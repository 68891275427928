/** @jsxRuntime classic */
/** @jsx jsx */
import { Link, jsx } from 'theme-ui'

const styles = {
  footer: {
    position: 'absolute',
    bottom: '0',
    width: '100%',
    height: '2.5rem',
  },
}

export const Footer = ({ links }) => {
  return (
    <footer sx={styles.footer}>
      {links &&
        links.map((link, id) => (
          <Link
            rel="noreferrer"
            target="_blank"
            href={link.url}
            key={`footer-link-${id}`}
          >
            {link.text}
          </Link>
        ))}
    </footer>
  )
}
